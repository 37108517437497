<template>
  <div class="row col-12" style="width: 100%; margin: 0 auto">
    <!-- <div class="centered">
      <h1>This is a locations page</h1>
    </div> -->
    <div class="centered">
      <h2>
        Add a new Contractor
      </h2>
    </div>
      <div class="centered">
        <div class="">
          <FormContractor
          :ExistingContractors="contractors"
          
            v-on:newContractorCreated="onNewContractorCreated"
          />
        </div>
      </div>
  </div>
</template>

<script lang="js">
import store from '@/store/index';
import { defineAsyncComponent, onMounted } from "vue";
import axios from "axios";
import FormContractor from "@/components/FormContractor.vue";

export default {
  data() {
    return {
      token: localStorage.getItem('userToken'),
      contractors: [],
    };
  },
    methods: {
      async loadContractors() {
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/contractorslist/");
      this.contractors = res.data;
    },
        onNewContractorCreated() {
this.loadContractors();
      }

    },
     components: {
       FormContractor,
     },
   created() {
    this.loadContractors();
  },
};
</script>

<style scoped>
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.toLeft {
  text-align: left;
}
p.margin-30 {
  margin-left: 30px;
}
</style>
