<template>
  <div class="container">
    <form v-on:submit.prevent="submitForm">
      <div class="form-group py-2">
        <div>
          <input
            type="text"
            class=""
            id="defect_name"
            placeholder="Defect name"
            v-model="form.defect_name"
          />
        </div>
      </div>
      <div class="form-group py-2">
        <div>
          <input
            type="text"
            class=""
            id="defect_description"
            placeholder="Defect description"
            v-model="form.defect_description"
          />
        </div>
      </div>
      <div class="form-group py-2">
        <div>
          <Dropdown
            v-model="form.defect_respondent"
            :options="availableRespondents"
            optionLabel="contractor_name"
            optionValue="contractor_id"
            selectionLimit="1"
            placeholder="Defect respondent"
            :filter="true"
          />
        </div>
      </div>
      <div class="form-group py-3">
        <button class="submitButton">Submit</button>
      </div>
    </form>
    <div>{{ infoString }}</div>
    <!-- <br>
    <div> possibleLocationParent: {{ possibleLocationParent }}</div> -->
  </div>
</template>

<script>
import axios from "axios";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";

export default {
  props: ["ExistingLocations"],
  name: "PostFormAxios",

  data() {
    return {
      form: {
        defect_name: "",
        defect_description: "",
        defect_location: "",
        defect_status: "",
        defect_respondent: "",
        reporter_id: "",
      },
      authorized_groups: "",
      availableRespondents: [],
      possibleLocationParent: [
        {
          location_id: 0,
          location_name: "None",
          parent_location_id: 0,
          authorized_groups: [],
        },
      ],
      infoString: "",
    };
  },
  methods: {
    submitForm() {
      this.form.defect_location = this.$route.params.id;
      axios
        .post("/defectslist/", this.form)
        .then((res) => {
          //Perform Success Action
          if (res.status === 201) {
            this.$emit("newLocationCreated");
            this.infoString = 'Defect "' + this.form.defect_name + '" created';
          }
        })
        .catch((error) => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
      // }
      // if (this.possibleLocationParent.includes(newLocation)){
      //   console.log("Such location already exists")
      // }
    },
    async getContractors() {
      const res = await axios.get("/contractorslist/");

      this.availableRespondents = res.data;
    },
  },
  created: function () {},
  components: {
    MultiSelect,
    Dropdown,
  },
  mounted() {
    this.getContractors();
  },
  watch: {
    ExistingLocations: function () {
      this.possibleLocationParent = this.possibleLocationParent.concat(
        this.ExistingLocations
      );
    },
  },
};
</script>

<style scoped>

.submitButton {
  background-color: #0d6efd;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 6px 15px;
  border-radius: 15px;
}
</style>
