<template>
  <div class="row col-12 centered">
    <div class="row col-12" v-if="subLocations.length > 0">
      <Divider align="left"> Sublocations </Divider>
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1"
        v-for="location of subLocations"
      >
        <router-link
          :to="{ name: 'LocationDetails', params: { id: location.location_id } }"
        >
          <div class="card card-style mt-2 circular">
            <div>
              <i class="fa-solid fa-building fa-4x"></i>
              <p class="text">
                {{ location.location_name }} ({{ location.location_id }})
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row col-12" v-if="defects.length > 0">
      <Divider align="left"> Defects </Divider>
      <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1" v-for="defect of defects">
        <router-link :to="{ name: 'DefectDetails', params: { id: defect.defect_id } }">
          <div class="card card-style mt-2 circular">
            <div>
              <i class="fa-solid fa-triangle-exclamation fa-4x"></i>
              <p class="text">{{ defect.defect_name }} (id:{{ defect.defect_id }})</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row col-12">
      <Divider align="left"> </Divider>
      <Accordion>
        <AccordionTab header="New defect">
          <FormDefect
            :ExistingLocations="locations"
            v-on:newDefectCreated="onNewDefectCreated"
          />
        </AccordionTab>
      </Accordion>
    </div>
    <div class="row col-12">
      <Divider align="left"> </Divider>
      <Accordion>
        <AccordionTab header="New sub-location">
          <form-location
            :parentLocation="$route.params.id"
            v-on:newDefectCreated="onNewDefectCreated"
          />
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store/index';
import { defineAsyncComponent, onMounted } from "vue";
import axios from "axios";
import FormDefect from "@/components/FormDefect.vue";
import FormLocation from "@/components/FormLocation.vue";
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  props: [
    "locationId",
  ],
  data() {
    return {
      token: localStorage.getItem('userToken'),
      locationDetails: [],
      subLocations: [],
      defects: [],
      defectsStatuses: [],
    };
  },
    methods: {
      async loadLocationDetails() {
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/location/"+this.$route.params.id+"/");
      console.log("response", res);
      this.locationDetails = res.data;
    },
     async loadSubLocations() {
      const locationId = parseInt(this.$route.params.id);

      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/locationslist/");
      var allLocations = res.data;
      console.log("allLocations:", allLocations)
      var subLocations = allLocations.filter(function (loc) {
        return loc.parent_location_id===locationId;

      })

      this.subLocations = subLocations;
    },
     async loadDefects() {
      const locationId = parseInt(this.$route.params.id);
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/location/"+this.$route.params.id+"/defects/");
      // const res = await axios.get("/defectstatus/"+this.$route.params.id+"/");
      var defects = res.data;
      console.log("fetched defects:", defects)

      this.defects = defects;
    },
     async loadDefectsStatuses() {
      const locationId = parseInt(this.$route.params.id);
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      // const res = await axios.get("/location/"+this.$route.params.id+"/defects/");
      const res = await axios.get("/defectstatus/"+this.$route.params.id+"/");
      var defectsStatuses = res.data;
      console.log("fetched defects:", defects)

      this.defectsStatuses = defectsStatuses;
    },
    onNewDefectCreated() {
    this.loadDefects();
    this.loadDefectsStatuses();
      }

    },
     components: {
       FormDefect,
       FormLocation,
       Divider,
       Accordion,
       AccordionTab,
  },
    mounted() {
      // console.log(this.$route.params.id) // outputs id of currentyly selected location
    this.loadLocationDetails();
    this.loadSubLocations();
    this.loadDefects();

  },
  watch: {

  }

};
</script>

<style scoped>
.fa-building,
.fa-circle-plus {
  color: #606366;
}
.fa-triangle-exclamation {
  color: #db2e57;
}
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.toLeft {
  text-align: left;
}
.text {
  color: #606366;
}
</style>
