<template>
  <div class="row col-12" style="width: 100%; margin: 0 auto">
    <div class="row toLeft">
      <div class="toLeft col-3" v-if="contractors.length > 0" style="margin: 0px auto">
        <p><h3>Available contractors:</h3></p>

        <ul style="list-style-type: none">
          <li v-for="contractor of contractors">
            <p class="margin-30">
              <h5>{{ contractor.contractor_name }} (id:{{ contractor.contractor_id }})</h5> {{ contractor.contractor_phone }}, {{ contractor.contractor_mail }}
              <!-- <router-link
                :to="{ name: 'ContractorDetails', params: { id: contractor.contractor_id } }"
              >
                {{ contractor.contractor_name }}, {{ contractor.contractor_id }}
              </router-link> -->
            </p>
          </li>
        </ul>
      </div>
      <div class="col-6 toLeft">
        <div class="toLeft" style="margin-right: 20px">
          <FormContractor
            :ExistingContractors="contractors"
            v-on:newContractorCreated="onNewContractorCreated"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store/index';
import { defineAsyncComponent, onMounted } from "vue";
import axios from "axios";
import FormContractor from "@/components/FormContractor.vue";

export default {
  data() {
    return {
      token: localStorage.getItem('userToken'),
      contractors: [],
    };
  },
    methods: {
      async loadContractors() {
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/contractorslist/");
      this.contractors = res.data;
    },
        onNewContractorCreated() {
this.loadContractors();
      }

    },
     components: {
       FormContractor,
     },
   created() {
    this.loadContractors();
  },
};
</script>

<style scoped>
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.toLeft {
  text-align: left;
}
p.margin-30 {
  margin-left: 30px;
}
</style>
