<template>
  <!-- <div v-if="isLoggedIn"> -->
  <div class="centered">
    <div class="row col-8 centered">
      <a href="/locations">
        <div class="card card-style mt-2 circular">
          <div>
              <i class="fa-solid fa-building fa-4x"></i>
              <p class="text">Select an existing location</p>
          </div>
        </div>
      </a>
      <a href="/newlocation">
        <div class="card card-style mt-2 circular">
          <div>
              <i class="fa-solid fa-circle-plus fa-4x"></i>
              <p class="text">Create a new location</p>
          </div>
        </div>
      </a>
      <a href="/newcontractor">
        <div class="card card-style mt-2 circular">
          <div>
              <i class="fa-solid fa-address-card fa-4x"></i>
              <p class="text">Add a new contractor</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.circular {
  border: 2px solid #606366;
  padding: 10px;
  border-radius: 30px;
}
.fa-building, .fa-circle-plus, .fa-address-card {
  color: #606366;
}
.text {
  color: #606366;
}
</style>
