<template>
  <div class="col-12 centered">
    <form v-on:submit.prevent="submitForm">
      <div class="py-2 centered">
        <!-- <label for="location_name">Location name</label> -->
        <div class="py-2">
          <input
            type="text"
            class=""
            id="location_name"
            placeholder="Location name"
            v-model="form.location_name"
          />
        </div>
      </div>
      <div class="py-2">
        <!-- <label for="authorized_groups">Select a Group</label> -->
        <div class="py-1">
          <MultiSelect
            v-model="form.authorized_groups"
            :options="availableGroups"
            optionLabel="name"
            optionValue="id"
            placeholder="Select a Group"
          />
        </div>
      </div>
      <div class="py-2">
        <!-- <label for="parent_location_id">Parent location</label> -->
        <div class="py-1" v-if="!parentLocation">
          <Dropdown
            v-model="form.parent_location_id"
            :options="possibleLocationParent"
            optionLabel="location_name"
            optionValue="location_id"
            selectionLimit="1"
            placeholder="Parent location"
            :filter="true"
          />
        </div>
      </div>

      <div class="py-3">
        <button class="submitButton">Submit</button>
      </div>
    <div>{{ infoString }}</div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";

export default {
  props: ["ExistingLocations", "parentLocation"],
  name: "PostFormAxios",

  data() {
    return {
      form: {
        location_name: "",
        authorized_groups: [],
        parent_location_id: "",
      },
      authorized_groups: "",
      availableGroups: [],
      possibleLocationParent: [
        {
          location_id: 0,
          location_name: "None",
          parent_location_id: 0,
          authorized_groups: [],
        },
      ],
      infoString: "",
    };
  },
  methods: {
    submitForm() {
      if (this.form.parent_location_id === "") {
        this.form.parent_location_id = "0";
      }
      const locationExists = this.possibleLocationParent.find(
        (element) =>
          element.location_name === this.form.location_name &&
          element.parent_location_id === parseInt(this.form.parent_location_id)
      );
      if(this.parentLocation){
        this.form.parent_location_id = this.parentLocation;
      };
      if (locationExists) {
        this.infoString =
          "Such location (name: " +
          this.form.location_name +
          ", parent location: " +
          this.possibleLocationParent.find(
            (element) => element.location_id === parseInt(this.form.parent_location_id)
          ).location_name +
          ") already exists";
      } else {
        axios
          .post("/locationslist/", this.form)
          .then((res) => {
            //Perform Success Action
            if (res.status === 201) {
              this.$emit("newLocationCreated");
              this.infoString = 'Location "' + this.form.location_name + '" created';
            }
          })
          .catch((error) => {
            // error.response.status Check status code
          })
          .finally(() => {
            //Perform action in always
          });
      }
      // if (this.possibleLocationParent.includes(newLocation)){
      //   console.log("Such location already exists")
      // }
    },
    async getUserGroups() {
      const res = await axios.get("/user/groups/");

      this.availableGroups = res.data;
    },
  },
  created: function () {},
  components: {
    MultiSelect,
    Dropdown,
  },
  mounted() {
    this.getUserGroups();
  },
  watch: {
    ExistingLocations: function () {
      this.possibleLocationParent = this.possibleLocationParent.concat(
        this.ExistingLocations
      );
    },
  },
};
</script>

<style scoped>
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
input {
  height: 40px;
  width: 200px;
}
.submitButton {
  background-color: #0d6efd;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 6px 15px;
  border-radius: 15px;
}
</style>
