<template>
  <div class="container">
    <form v-on:submit.prevent="submitForm">
      <div class="form-group py-2">
        <label for="contractor_name">Contractor name</label>
        <div class="py-1">
          <input
            type="text"
            class=""
            id="contractor_name"
            placeholder="Contractor name"
            v-model="form.contractor_name"
          />
        </div>
      </div>
      <div class="form-group py-2">
        <label for="contractor_mail">Contractor mail</label>
        <div class="py-1">
          <input
            type="text"
            class=""
            id="contractor_mail"
            placeholder="Contractor mail"
            v-model="form.contractor_mail"
          />
        </div>
      </div>
      <div class="form-group py-2">
        <label for="contractor_phone">Contractor phone</label>
        <div class="py-1">
          <input
            type="text"
            class=""
            id="contractor_phone"
            placeholder="Contractor phone"
            v-model="form.contractor_phone"
          />
        </div>
        <div class="py-1">
          <MultiSelect
            v-model="form.authorized_groups"
            :options="availableGroups"
            optionLabel="name"
            optionValue="id"
            placeholder="Select a Group"
          />
        </div>
      </div>

      <div class="form-group py-3">
        <button class="submitButton">Submit</button>
      </div>
    </form>
    <div class="row col-12">
      <div class="infoText">{{ infoString }}</div>
      <div class="warningText">{{ warningString }}</div>
    </div>
    <div class="row col-12">
      <Divider align="left"> </Divider>
      <Accordion>
        <AccordionTab header="Available contractors">
          <div class="row col-12" v-for="contractor in ExistingContractors">
            {{ contractor.contractor_name }}
            {{ contractor.contractor_mail }}
            {{ contractor.contractor_phone }}
            <Divider align="left"> </Divider>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  props: ["ExistingContractors"],
  name: "PostFormAxios",

  data() {
    return {
      form: {
        contractor_name: "",
        contractor_mail: "",
        contractor_phone: "",
        authorized_groups: [],
      },
      availableGroups: [],
      availableRespondents: [],
      infoString: "",
      warningString: "",
    };
  },
  methods: {
    submitForm() {
      this.form.contractor_name = this.form.contractor_name.trim();
      let duplicate = this.ExistingContractors.find(
        (element) => element.contractor_name === this.form.contractor_name
      );
      if (duplicate) {
        this.warningString = "Such contracotr already exists";
        setTimeout(() => (this.warningString = ""), 5000);
      } else {
        this.form.defect_location = this.$route.params.id;
        axios
          .post("/contractorslist/", this.form)
          .then((res) => {
            //Perform Success Action
            if (res.status === 201) {
              this.$emit("newLocationCreated");
              this.infoString = 'Contractor "' + this.form.contractor_name + '" saved';
              setTimeout(() => (this.infoString = ""), 5000);
            } else if (res.status === 400) {
              this.warningString = "Error, ";
            }
          })
          .catch((error) => {
            // error.response.status Check status code
          })
          .finally(() => {
            //Perform action in always
          });
      }
      // }
      // if (this.possibleLocationParent.includes(newLocation)){
      //   console.log("Such location already exists")
      // }
    },
    // async getContractors() {
    //   const res = await axios.get("/contractorslist/");

    //   this.availableRespondents = res.data;
    // },
        async getUserGroups() {
      const res = await axios.get("/user/groups/");

      this.availableGroups = res.data;
    },
  },
  created: function () {},
  components: {
    MultiSelect,
    Dropdown,
    Divider,
    Accordion,
    AccordionTab,
  },
  mounted() {
    this.getUserGroups();
  },
  watch: {
    ExistingLocations: function () {
      this.possibleLocationParent = this.possibleLocationParent.concat(this.ExistingLocations);
    },
  },
};
</script>

<style scoped>
.submitButton {
  background-color: #0d6efd;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 6px 15px;
  border-radius: 15px;
}
div.infoText {
  margin-top: -6px;
  font-size: 16px;
  color: green;
}
div.warningText {
  margin-top: -6px;
  font-size: 16px;
  color: red;
}
</style>
