<template>
  <div class="row col-12 centered">
    <form v-on:submit.prevent="submitForm">
      <div class="toLeft py-1">
        <label for="defect_name">Defect name</label>
        <div>
          <input
            type="text"
            class=""
            id="defect_name"
            placeholder="Defect name"
            v-model="form.defect_name"
          />
        </div>
      </div>
      <div class="toLeft py-1">
        <label for="defect_description">Defect description</label>
        <div>
          <Textarea
            v-model="form.defect_description"
            :autoResize="true"
            rows="3"
            cols="25"
          />
        </div>
      </div>
      <!--  -->
      <div class="toLeft py-1">
        <label for="defect_respondent">Defect status</label>
        <div class="centered">
          <Dropdown
            v-model="form.defect_status"
            :options="possibleDefectStatuses"
            optionLabel="label"
            optionValue="id"
            selectionLimit="1"
            :placeholder="defectStatusLabel"
            :filter="false"
          />
        </div>
      </div>
      <!--  -->
      <div class="toLeft py-1">
        <label for="defect_respondent">Defect respondent</label>
        <div class="centered">
          <Dropdown
            v-model="form.defect_respondent"
            :options="passedAvailableRespondents"
            optionLabel="contractor_name"
            optionValue="contractor_id"
            selectionLimit="1"
            placeholder="Defect respondent"
            :filter="true"
          />
        </div>
      </div>

      <div class="form-group py-3">
        <button class="submitButton">Update</button>
      </div>
    </form>
  </div>
</template>

<script>
import store from "@/store/index";
import axios from "axios";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";

export default {
  props: ["passedDefect", "passedDefectStatus", "passedAvailableRespondents"],
  name: "PostFormAxios",

  data() {
    return {
      form: {
        defect_name: "",
        defect_description: "",
        defect_status: "",
        defect_location: "",
        defect_respondent: "",
        reporter_id: "",
      },
      defectStatusLabel: "empty label2",
      possibleDefectStatuses: [
        { label: "Unrepaired", id: 0 },
        { label: "Awaiting Approval", id: 1 },
        { label: "Needs improvement", id: 2 },
        { label: "Repaired", id: 3 },
        { label: "Contractor replacement", id: 4 },
        { label: "Repaired by replacer", id: 5 },
      ],
      noOfFiles: 0,
      fileUrlsList: [],
    };
  },
  methods: {
    usePassedData() {
      if (this.passedDefect && this.passedDefectStatus) {
        this.form.defect_name = this.passedDefect.defect_name;
        this.form.defect_description = this.passedDefectStatus.defect_description;
        this.form.defect_status = this.passedDefectStatus.defect_status;
        this.form.defect_location = this.passedDefect.defect_location;
        this.form.defect_respondent = this.passedDefect.defect_respondent;
        this.form.reporter_id = this.passedDefect.defect_reporter;
        this.defectStatusLabel = this.possibleDefectStatuses.find(
          (x) => x.id === parseInt(this.passedDefectStatus.defect_status)
        ).label;
      }
    },
    submitForm() {
      axios
        .put("/defect/" + this.$route.params.id + "/", this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("defectUpdated");
          }
        })
        .catch((error) => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  created: function () {},
  components: {
    MultiSelect,
    Dropdown,
    Textarea,
  },
  mounted() {
    // this.getContractors();
  },
  watch: {
    passedDefect: function () {
      console.log("passedDefect changed");
      this.usePassedData();
    },
    passedDefectStatus: function () {
      console.log("passedDefectstatus changed");
      this.usePassedData();
    },
  },
};
</script>

<style scoped>
div.toLeft {
  text-align: left;
}
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
.submitButton {
  background-color: #0d6efd;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 6px 15px;
  border-radius: 15px;
}
</style>
