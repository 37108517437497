<template>
  <div class="row col-12 locations centered">
    <div class="row col-12 col-xl-8 toLeft">
      <!-- place defect info here -->
      <div class="row col-12" style="margin-top: -16px">
        <div class="title">Defect name:</div>
        <div class="text">
          {{ defect.defect_name }}
        </div>
      </div>
      <div class="row col-12">
        <div class="title">Defect description:</div>
        <div class="text">
          {{ defectStatus.defect_description }}
        </div>
      </div>
      <div class="row col-12">
        <div class="title">Defect status:</div>
        <div class="text">
          {{ defectStatusLabel }}
        </div>
      </div>
      <div class="row col-12">
        <div class="title">Defect respondent:</div>
        <div class="text">
          {{ defectRespondent }}
        </div>
      </div>
    </div>
    <div class="row col-12">
      <div class="infoText">
        {{ infoString }}
      </div>
    </div>
    <div class="row col-12 col-xl-3">
      <Divider align="left">Upload files</Divider>
      <div class="col-12 toLeft">
        <FileUpload
          :customUpload="true"
          @uploader="myUploader"
          :multiple="true"
          :disabled="uploadDisabled"
        />
      </div>
    </div>
    <!--  -->
    <div class="row col-12 col-xl-3">
      <Accordion>
        <AccordionTab header="Images">
          <div class="row col-12 col-xl-8">
            <div class="col-xl-5" v-for="media in medias">
              <img :src="media.media_path" class="image" />
              <Divider align="left"></Divider>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>

    <div class="row col-12 col-xl-3">
      <!-- <Divider align="left"> Edit defect </Divider> -->
      <Divider align="left"></Divider>
      <Accordion>
        <AccordionTab header="Edit defect" class="accordionHeader">
          <div class="col-12 col-xl-4 centered">
            <div class="toLeft">
              <FormEditDefect
                :passedDefect="this.defect"
                :passedDefectStatus="this.defectStatus"
                :passedAvailableRespondents="this.availableRespondents"
                v-on:defectUpdated="onDefectUpdated"
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store/index';
import { defineAsyncComponent, onMounted } from "vue";
import axios from "axios";
import FormEditDefect from "@/components/FormEditDefect.vue";
import FileUpload from "primevue/fileupload";
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  props: [
    "locationId",
  ],
  data() {
    return {
      token: localStorage.getItem('userToken'),
      subLocations: [],
      defect: [],
      defectStatus: [],
      fileUrlsList: [],
      medias: [],
      availableRespondents: [],
      possibleDefectStatuses: [
        { label: "Unrepaired", id: 0 },
        { label: "Awaiting Approval", id: 1 },
        { label: "Needs improvement", id: 2 },
        { label: "Repaired", id: 3 },
        { label: "Contractor replacement", id: 4 },
        { label: "Repaired by replacer", id: 5 },
      ],
      defectStatusLabel: '',
      infoString: '',
      uploadDisabled: false,
    };
  },
    methods: {
    async loadDefect() {
      const locationId = parseInt(this.$route.params.id);
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[authorizationHeader] = `Bearer ${userToken}`;
      const res = await axios.get(
        "/defect/" + this.$route.params.id + "/"
      );
      this.defect = res.data;
      if(this.availableRespondents.length>0){
        this.defectRespondent = this.availableRespondents.find(x => x.contractor_id === parseInt(this.defect.defect_respondent)).contractor_name;
      }
    },

    async loadDefectStatus() {
      const locationId = parseInt(this.$route.params.id);
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[authorizationHeader] = `Bearer ${userToken}`;
      const res = await axios.get(
        "/defectstatus/" + this.$route.params.id + "/"
      );
      console.log("res.data", res.data);
      this.defectStatus = res.data;
      this.defectStatusLabel = this.possibleDefectStatuses.find(x => x.id === parseInt(this.defectStatus.defect_status)).label;
    },
    async getContractors() {
      const res = await axios.get("/contractorslist/");

      this.availableRespondents = res.data;
      if(this.defect.length>0){
        this.defectRespondent = this.availableRespondents.find(x => x.contractor_id === parseInt(this.defect.defect_respondent)).contractor_name;
      }
    },
      async loadSubLocations() {
      const locationId = parseInt(this.$route.params.id);

      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/locationslist/");
      var allLocations = res.data;
      console.log("allLocations:", allLocations)
      var subLocations = allLocations.filter(function (loc) {
        return loc.parent_location_id===locationId;

      })

      this.subLocations = subLocations;
    },
      async loadMedia() {
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/media/"+this.$route.params.id+"/");
      this.medias = res.data;
    },
    async myUploader(event) {
      this.uploadDisabled = true;
      this.noOfFiles = event.files.length;
      this.fileUrlsList = [];
      var urlsList = [];
      for (const x of Array(event.files.length).keys()) {
        var formData = new FormData();
        formData.append("file", event.files[x]);
        let res = await axios
          .post("/upload/"+this.$route.params.id+"/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        urlsList.push(res.data);
      };
      var newFormat = Array.from(urlsList);
      this.fileUrlsList = newFormat;
      this.submitMediaUrls();

    },
    submitMediaUrls() {
      var urls = Array.from(this.fileUrlsList)
      var mediaForm = {defect_id: this.$route.params.id, media_path: this.fileUrlsList}
        axios
        .post("/mediaslist/", mediaForm)
        .then((res) => {
          if (res.status === 201) {
            this.loadMedia();
            this.uploadDisabled = false;
            this.notifyUploaded();
          }
        })
        .catch((error) => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    notifyUploaded() {
      this.infoString = "Files sent successfully";
      setTimeout(() => (this.infoString = ''), 5000);
    },
    onDefectUpdated(){
      this.loadDefect();
      this.loadDefectStatus();
    },


  },
    components: {
      FormEditDefect,
      FileUpload,
      Divider,
      Accordion,
      AccordionTab,
    },
    mounted() {
      this.loadDefect();
      this.loadDefectStatus();
      this.getContractors();
      this.loadMedia();
      // console.log(this.$route.params.id) // outputs id of currentyly selected location

  },
  watch: {

  }

};
</script>

<style scoped>
div.toLeft {
  text-align: left;
}
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.title {
  margin-top: 3px;
  font-size: 12px;
}
div.text {
  margin-top: -6px;
  font-size: 16px;
}
div.infoText {
  margin-top: -6px;
  font-size: 16px;
  color: green;
}
.image {
  max-width: 100%;
  /* margin-left: 50px; */
  margin-top: 20px;
}
.p-accordion {
  /* height: 20px; */
  font-size: 14px;
}

/* .p-fileupload {
  scale: 20%;
} */
</style>
