<template>
  <div class="centered">
    <div class="row col-12 centered">
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1 location"
        v-for="location of locations"
      >
          <router-link
            :to="{ name: 'LocationDetails', params: { id: location.location_id } }"
          >
            <div class="card card-style mt-2 circular">
              <div>
                <i class="fa-solid fa-building fa-4x"></i>
                <div class="text">
                  <!-- {{ location.location_name }} ({{ location.location_id }}) -->
                  {{ location.location_name }}
                </div>
              </div>
            </div>
          </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store/index';
import { defineAsyncComponent, onMounted } from "vue";
import axios from "axios";
import FormLocation from "@/components/FormLocation.vue";

export default {
  data() {
    return {
      token: localStorage.getItem('userToken'),
      locations: [],
    };
  },
    methods: {
      async loadLocations() {
      const authorizationHeader = "Authorization";
      const userToken = store.state.access;
      axios.defaults.headers.common[
        authorizationHeader
      ] = `Bearer ${userToken}`;
      const res = await axios.get("/locationslist/");
      // this.locations = res.data; // now only main locations are displayed
      this.locations = res.data.filter(loc => loc.parent_location_id===0);

    },
        onNewLocationCreated() {
this.loadLocations();
      }

    },
     components: {
       FormLocation,
     },
   created() {
    this.loadLocations();
  },
};
</script>

<style scoped>
div.centered {
  text-align: center;
  display: flex;
  justify-content: center;
}
div.toLeft {
  text-align: left;
}
p.margin-30 {
  margin-left: 30px;
}
div.circular {
  border: 2px solid #606366;
  padding: 10px;
  border-radius: 30px;
}
.fa-building,
.fa-circle-plus {
  color: #606366;
}
.text {
  color: #606366;
}
.location {
  display: table;
}
</style>
